<template>
   <div @scroll="fixheader" id="header" ref="header" :class="[{'bg-transparent':page == 'Home', ' bg-white ':page != 'Home'}, bg]" class="sticky top-0 z-50 w-full mx-auto">
        <div class="flex items-center justify-between py-1 mx-3 lg:mx-16">
            <div class="text-base font-semibold" >
                <router-link to="/">
                    <img src="@/assets/svg/logo-side.svg" class="w-auto h-16" alt="">
                </router-link>
            </div>
            <div v-if="page == 'Home' && showHeaderAddress == true" class="hidden text-sm text-center md:block w-96" >
                <div class="z-10 flex items-center " >
                    <div class="flex items-center justify-around w-full h-10 pl-2 space-x-2 bg-gray-100 rounded-full">
                    <div class="flex items-center ">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                        </svg>
                    </div>
                    <div class="w-1 h-6 bg-gray-200 rounded-full" ></div>
                        <vue-google-autocomplete
                            ref="address"
                            id="map"
                            placeholder="Enter delivery Address .."
                            classname="bg-gray-100 w-full focus:outline-none rounded-r-full" 
                            v-on:placechanged="getAddressData"
                            country="ng"
                            v-model="deliveryAddress"
                            @keyup.enter="SortAddress">
                        </vue-google-autocomplete> 
                    </div>
                    <div class="z-10 h-10 -ml-8">
                        <button class="flex items-center justify-center w-10 h-full border-4 border-white rounded-full shadow-md bg-brand" @click="SortAddress">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd" />
                        </svg>
                        </button>
                    </div>
                </div>
            </div>
            <div class="hidden md:block" v-if="this.$route.name == 'ResturantsPage'">
                <SearchFood :restaurant="this.$store.state.storeDetails"/>
            </div>
            <div class="flex justify-end">
                <div  v-if="!loggedUser">
                    <router-link :to="{name:'Home'}" v-if="this.$route.name == 'CustomerSignUp' || this.$route.name  == 'CustomerSignIn' || this.$route.name  == 'CustomerForgotPassword' || this.$route.name  == 'CustomerVerifyEmail' || this.$route.name  == 'CustomerChangePassword' || this.$route.name  == 'CustomerConfirmEmail'  " :class="{'bg-deep-brand':page == 'Home', 'bg-black text-brand ':page != 'Home'}" class="block px-6 py-3 text-sm font-semibold text-white rounded-full lg:hidden" >
                        Back home
                    </router-link>
                    <button v-else @click="toggleshowPopup" :class="{'bg-deep-brand':page == 'Home', 'bg-black text-brand ':page != 'Home'}" class="hidden px-6 py-3 text-sm font-semibold text-white rounded-full lg:block" >
                        Get started
                    </button>
                </div>
                <div v-else class="hidden space-x-3 lg:flex">
                    <div class="relative flex items-center">
                        <div @click="toogleProfileMenu" class="flex items-center space-x-2 cursor-pointer">
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </div>
                            <p class="text-sm">{{ loggedUser.firstName }}</p>
                        </div>
                        <div v-if="showProfileMenu">
                            <BigDropdown  :activeuser="activeuser" @updateCustomer="GetUserProfile"  @closeDropdwn="toogleProfileMenu" :userDetails="loggedUser" /> 
                        </div>
                    </div> 
                    <button @click="checkout" class="relative block p-2 mx-8 text-gray-100 bg-black rounded" >
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
                            </svg>
                        <div class="absolute flex items-center justify-center w-5 h-5 text-sm text-gray-100 bg-red-500 rounded-full -top-2 -right-2" >
                            {{ cart }}
                        </div>
                    </button>
                </div>
                <div class="flex justify-end" >
                    <div  v-if="!loggedUser">
                        <router-link :to="{name:'Home'}" v-if="this.$route.name == 'CustomerSignUp' || this.$route.name  == 'CustomerSignIn' || this.$route.name  == 'CustomerForgotPassword' || this.$route.name  == 'CustomerVerifyEmail' || this.$route.name  == 'CustomerChangePassword' || this.$route.name  == 'CustomerConfirmEmail'  " :class="{'bg-deep-brand':page == 'Home', 'bg-black text-brand ':page != 'Home'}" class="hidden px-6 py-3 text-sm font-semibold text-white rounded-full lg:block" >
                            Back home
                        </router-link>
                        <button v-else @click="toggleshowPopup" class="px-6 py-3 text-sm font-semibold bg-black rounded-full lg:hidden text-brand" >
                            Get started
                        </button>
                    </div>
                    <div v-else class="flex space-x-3">
                        <button v-if="showIcon" @click="checkout" class="relative block p-2 text-gray-100 bg-black rounded lg:hidden" >
                            <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
                                </svg>
                            <div class="absolute flex items-center justify-center w-4 h-4 text-xs text-gray-100 bg-red-500 rounded-full -top-2 -right-2" >
                                {{ cart }}
                            </div>
                        </button>
                        <button id="mobileMenu" class="z-50 text-black lg:hidden" @click="toogleNav" >
                            <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16m-7 6h7" />
                            </svg>
                        </button>
                    </div>
                </div>
            
            </div>
        </div>
    </div>
        <div v-if="showNav">
             <MobileNav @closeNav="toogleNav" :loggedUser="loggedUser" :activeuser="activeuser" @updateWallet="GetUserProfile"  :showNav="showNav" />
        </div>
    <Modal v-if="showPopup" :show="true" width="lg:w-768">
        <div class="flex justify-end">
            <button @click="toggleshowPopup">
                <svg class="w-8 h-8 text-black"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
                </svg>
            </button>
        </div>
        <div class="grid gap-4 p-4 lg:grid-cols-2">
            <div class="p-4 bg-gray-100 rounded-md shadow-md">
                <div class="grid grid-rows-4 gap-4">
                     <div>
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-16 h-16 text-deep-brand" viewBox="0 0 20 20" fill="currentColor">
                            <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
                        </svg>
                    </div>
                    <h1 class="text-2xl font-semibold">
                        I want to order <br> FOOD
                    </h1>
                    <div class="text-sm">
                        <p>I love great meals and will order with friends and loved ones for exciting shared experiences</p>
                    </div>
                    <div class="flex items-end">
                            <button @click="pushLogin" class="px-4 py-2 text-sm font-semibold rounded bg-brand" >For Customers</button>
                    </div>
                </div>  
            </div>
            <div class="p-4 bg-gray-100 rounded-md shadow-md">
                <div class="grid grid-rows-4 gap-4">
                     <div>
                        <img src="https://img.icons8.com/color/96/000000/restaurant-.png" class="w-16 h-16"/>
                    </div>
                     <h1 class="text-2xl font-semibold">
                        I own a <br> RESTAURANT
                    </h1>
                    <div  class="text-sm">
                        <p>We operate high quality restaurants that make great meals for anyone looking to eat and stay healthy</p>
                    </div>
                    <div class="flex items-end">
                        <a :href="getVendorUrl()"  class="px-4 py-2 text-sm font-semibold rounded bg-brand" >For Vendors</a>
                   </div>
                </div>  
            </div>
        </div>
    </Modal>
</template>

<script>
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import MobileNav from "@/components/MobileNav.vue";
import BigDropdown from "@/components/BigDropdown.vue";
import Modal from '@/components/Modal.vue';
import SearchFood from '@/components/SearchFood.vue';

export default {
    components:{
        MobileNav,BigDropdown,VueGoogleAutocomplete,Modal,SearchFood
    },
    emits: ["closeDropdwn","updateCustomer"],
    props:['shadow','page','bg'],
    data(){
        return{
            showNav: false,
            showProfileMenu:false,
            activeuser:{},
            showIcon:(this.$route.name != 'ResturantsPage'),
            deliveryAddress:"",
            showHeaderAddress:false,
            showPopup:false,
            GetUserProfile:{}
        }
    },
    methods:{
        getVendorUrl(){
        return (window.location.hostname.replace('www.','') == "noshtrybe.com") ? 'https://vendor.noshtrybe.com' : 'https://vendor.dev.noshtrybe.com';
        },
        toogleNav(){
            this.showNav = !this.showNav    
        },
        toggleshowPopup(){
        this.showPopup = !this.showPopup 
        },
        toogleProfileMenu(){
            this.showProfileMenu = !this.showProfileMenu   
        },
        pushLogin(){
            this.$router.push({ name:"CustomerSignIn" })
        },
        checkout(){
            if(this.$store.getters.getOrderStoreName && (this.$store.getters.getCart.cartItems.length || this.$store.getters.getCart.containers.length)) {
                let publicURL = this.$store.getters.getOrderStoreName;
                this.$router.push({ name: 'ResturantsPage', params : { publicUrl: publicURL } })   
            }else{
                this.$router.push({ name:"Checkout" })
            }
        },
        fixheader(){
            let scroll = window.scrollY;
            var self = this
            let menu = document.querySelector("#header")
            if (scroll > 10) {
                menu.classList.add("bg-white","shadow-md");
            }else{
                menu.classList.remove("bg-white","shadow-md");
            }
            if(this.$route.name == 'Home'){
                if(scroll < 300){
                    self.showHeaderAddress = false
                }else{
                    self.showHeaderAddress = true
                }
            }
            
        },
        getAddressData: function (addressData) {
            this.address = addressData;
            this.address.address = (addressData.street_number) ?`${addressData.street_number}, ${addressData.route}, ${addressData.locality}, ${addressData.country}`:`${addressData.route} ${addressData.locality}, ${addressData.country}`
            this.deliveryAddress = this.address.address
            this.$refs.address.update(this.deliveryAddress);
        },
        SortAddress(){
        this.address = (this.address) ? this.address : this.$store.getters.getAddress
        if(this.deliveryAddress && this.deliveryAddress ==  this.address.address){
            let geoData ={
                address: this.address.address,
                latitude:this.address.latitude,
                longitude: this.address.longitude
            }
            this.$store.dispatch('addDeliveryaddress',JSON.stringify(geoData));
            this.$router.push({ name: 'Resturants' });
            this.$store.dispatch('errAddress', '')
        }else{
            this.$store.dispatch('errAddress', 'Please enter your delivery address')
        }
        
        }
    },
    watch:{
        showHeaderAddress: function(){
            if(this.showHeaderAddress == true){
                setTimeout(() => {
                this.deliveryAddress = this.$store.getters.getAddress.address
                this.$refs.address.update(this.deliveryAddress) 
                }, 100);
            
            }
        }
    },
    computed:{
        cart(){
            return this.$store.getters.getcountCart
        },
        loggedUser(){
            return this.$store.getters.getUser
        }
    },
    beforeMount(){
        this.activeuser = this.$store.state.userProfile
    },
    created(){
        window.addEventListener('scroll', this.fixheader);
    }
}
</script>

<style scoped>
.router-link-active ,.router-link-exact-active{
    color:#c0d800
}
</style>